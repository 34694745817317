import memoizeOne from 'memoize-one';

export interface EnvVars {
    apiV2Url: string;
    apiLegacyUrl: string;
    env: string;
    adminLegacyUrl: string;
    adminV2Url: string;
}

export const getEnvVariables = (): EnvVars => {
    const result: EnvVars = {
        apiV2Url: process.env.REACT_APP_API_URL!,
        apiLegacyUrl: process.env.REACT_APP_API_LEGACY_URL!,
        env: process.env.REACT_APP_CLOUD_ENV!,
        adminLegacyUrl: process.env.REACT_APP_LEGACY_ADMIN_URL!,
        adminV2Url: process.env.REACT_APP_V2_ADMIN_URL!,
    };

    try {
        const windowObj = window as any;
        result.apiV2Url =
            windowObj && windowObj.REACT_APP_API_URL
                ? windowObj.REACT_APP_API_URL
                : process.env.REACT_APP_API_URL;
        result.apiLegacyUrl =
            windowObj && windowObj.REACT_APP_API_LEGACY_URL
                ? windowObj.REACT_APP_API_LEGACY_URL
                : process.env.REACT_APP_API_LEGACY_URL;
        result.env =
            windowObj && windowObj.REACT_APP_CLOUD_ENV
                ? windowObj.REACT_APP_CLOUD_ENV
                : process.env.REACT_APP_CLOUD_ENV;
        result.adminLegacyUrl =
            windowObj && windowObj.REACT_APP_LEGACY_ADMIN_URL
                ? windowObj.REACT_APP_LEGACY_ADMIN_URL
                : process.env.REACT_APP_LEGACY_ADMIN_URL;
        result.adminV2Url =
            windowObj && windowObj.REACT_APP_V2_ADMIN_URL
                ? windowObj.REACT_APP_V2_ADMIN_URL
                : process.env.REACT_APP_V2_ADMIN_URL;

        return result;
    } catch {
        return result;
    }
};

export default memoizeOne(getEnvVariables);
